'use client';

import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import useUser from '@haaretz/s-atoms/user';
import Icon from '@haaretz/s-icon';
import SuccessIcon from '@haaretz/s-success-icon';
import * as React from 'react';
import s9 from 'style9';

import EmailValidationActionButton from '../../ActionButton/EmailValidationActionButton';

import type { ActionButtonProps } from '../../ActionButton/';

type EmailValidationProps = Omit<ActionButtonProps, 'actionText'>;

// `c` is short for `classNames`
const c = s9.create({
  textWithColor: {
    fontWeight: 700,
    color: color('primary1000'),
  },
  separator: {
    width: '100%',
    marginBottom: space(5),
    ...border({
      color: color('primary300'),
      spacing: 5,
      side: 'bottom',
      style: 'solid',
      width: '1px',
    }),
    ...merge({
      ...mq({ from: 's', value: { marginBottom: space(4) } }),
      ...mq({ from: 'xl', value: { marginBottom: space(5) } }),
    }),
  },
  emailTitleText: {
    ...typesetter(1),
    marginBottom: space(1),
    ...merge({
      ...mq({ from: 's', value: { ...typesetter(2) } }),
      ...mq({ from: 'xl', value: { marginBottom: space(2) } }),
      ...mq({ from: 'xxl', value: { ...typesetter(1) } }),
    }),
  },
  emailDescriptionText: {
    ...typesetter(0),
    marginBottom: space(3),
    ...merge({
      ...mq({ from: 's', value: { ...typesetter(1) } }),
      ...mq({ from: 'xl', value: { marginBottom: space(5) } }),
      ...mq({ from: 'xxl', value: { ...typesetter(0) } }),
    }),
  },
  emailContent: {
    ...mq({
      until: 's',
      value: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      },
    }),
    ...mq({ from: 's', value: { maxWidth: space(66) } }),
    ...mq({ from: 'xl', value: { maxWidth: space(77) } }),
    ...mq({ from: 'xxl', value: { maxWidth: space(100) } }),
  },
  statusText: {
    marginTop: space(5),
    ...typesetter(0),
    ...merge({
      ...mq({
        from: 'xxl',
        value: {
          ...typesetter(0),
        },
      }),
    }),
  },
  success: {
    fontWeight: 700,
    color: color('quaternary700'),
  },
  failed: {
    display: 'flex',
    alignItems: 'center',
    color: color('secondary900'),
  },
  iconSuccess: {
    display: 'inline-flex',
  },
});

const userTexts = {
  trial: {
    emailTitleText: 'רוצים להינות ממינוי ההיכרות שלכם?',
    emailDescriptionText: 'אמתו את כתובת המייל שלכם על מנת להתחיל',
  },
  nonTrial: {
    emailTitleText: 'לא בשלים לרכוש מינוי?',
    emailDescriptionText: 'אמתו את כתובת המייל שלכם על מנת להינות מעד 6 כתבות חינם בחודש',
  },
};

const emailButtonText = 'שלחו לי מייל אימות';
const emailSuccessText1 = 'מייל האימות נשלח';
const emailSuccessText2 = 'יש ללחוץ על כפתור האישור להמשך הגלישה באתר';
const emailErrorText = 'לא הצלחנו לשלוח אליך מייל אימות';

export type Status = 'none' | 'success' | 'failed';

export default function EmailValidation({
  actionType,
  actionUrl,
  featureName,
  featureType,
  campaignName,
  campaignDetails,
  metaData,
  testGroup,
}: EmailValidationProps) {
  const [status, setStatus] = React.useState<Status>('none');
  const user = useUser('cookieValue');

  const isTrialUser = user.extendedUserType === 'Trial';
  const { emailTitleText, emailDescriptionText } = userTexts[isTrialUser ? 'trial' : 'nonTrial'];

  return (
    <div className={s9(c.emailContent)}>
      <div className={s9(c.separator)} />
      {status !== 'success' && (
        <>
          <div className={s9(c.emailTitleText, c.textWithColor)}>{emailTitleText}</div>
          <div className={s9(c.emailDescriptionText)}>{emailDescriptionText}</div>
        </>
      )}

      <EmailValidationActionButton
        actionText={emailButtonText}
        actionType={actionType ?? undefined}
        actionUrl={actionUrl}
        featureName={featureName ?? undefined}
        featureType={featureType ?? undefined}
        campaignName={campaignName ?? undefined}
        campaignDetails={campaignDetails ?? undefined}
        metaData={metaData}
        testGroup={testGroup}
        onActionComplete={data => {
          const result = data?.success ? 'success' : 'failed';
          setStatus(result);
        }}
      />

      {/* <EmailValidationButton setStatus={setStatus} status={status} onAction={onAction} /> */}

      {status !== 'none' &&
        (status === 'success' ? (
          <div className={s9(c.statusText)}>
            <span className={s9(c.success)}>
              <SuccessIcon styleExtend={[c.iconSuccess]} /> {emailSuccessText1}{' '}
            </span>
            {emailSuccessText2}
          </div>
        ) : (
          <div className={s9(c.statusText, c.failed)}>
            <Icon icon="warn" /> {emailErrorText}
          </div>
        ))}
    </div>
  );
}
