import type { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';

export default function getImgTitle(
  caption: ImageFragment['caption'],
  credit: ImageFragment['credit']
) {
  const trimmedCaption = caption?.trim();
  const trimmedCredit = credit?.trim();

  if (!trimmedCaption || !trimmedCredit) return undefined;

  const captionStr = !trimmedCaption.endsWith('.') ? `${trimmedCaption}.` : trimmedCaption;
  const title = `${captionStr} ${trimmedCredit}`;

  return title;
}
