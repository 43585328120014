import { hostname as hostnameWithoutSubdomain, site } from '@haaretz/s-consts';
import hexEnc from 'crypto-js/enc-hex';
import sha256 from 'crypto-js/sha256';

import type { CircularObjectType } from '@haaretz/s-types';
import type { QueryFunctionContext } from '@tanstack/react-query';

const hostname = `www.${hostnameWithoutSubdomain}`;

interface FetchPropsGet {
  url: string;
  headers?: RequestInit['headers'];
  options:
    | {
        cache: RequestInit['cache'];
        next?: Omit<NonNullable<RequestInit['next']>, 'revalidate'>;
      }
    | {
        cache?: never;
        next: RequestInit['next'] & { revalidate: NonNullable<RequestInit['next']>['revalidate'] };
      };
  searchParams?: URLSearchParams;
  method: 'GET';
}

interface FetchPropsPost<TBody> extends Omit<FetchPropsGet, 'method'> {
  method: 'POST';
  body: TBody;
}

export default function fetchUtility<
  TData extends object,
  TBody extends
    | CircularObjectType<string, string | number | boolean | undefined | null>
    | undefined = never,
>(
  props: FetchPropsGet | FetchPropsPost<TBody>
): (context?: Pick<QueryFunctionContext, 'signal'>) => Promise<TData> {
  const { url, method, headers, options, searchParams } = props;
  return async function fetcher(context) {
    try {
      if (options && options.next?.tags?.length) {
        for (let i = 0; i < options.next.tags.length; i++) {
          const tag = options.next.tags[i];

          if (tag && tag.length > 256) {
            options.next.tags[i] = sha256(tag).toString(hexEnc);
          }
        }
      }

      const { host, connection, ...defaultHeaders } = {
        ...(headers || {}),
        'content-type': 'application/json',
        accept: 'application/json',
        hostname,
      } as Record<string, string>;

      if (typeof window === 'undefined') {
        defaultHeaders.showcacheheaders = 'true';
        defaultHeaders['X-Forwarded-Host'] = `${site}.${hostnameWithoutSubdomain}`;
      }

      const { signal } = context || {};

      let fetchUrl = url;

      const searchParamsString = searchParams?.toString();

      if (searchParamsString) {
        fetchUrl = `${url}?${searchParamsString}`;
      }

      const result = await fetch(fetchUrl, {
        method,
        credentials: 'include',
        ...(typeof window === 'undefined' ? { headers: defaultHeaders } : {}),
        signal, // || timeoutSignal // TODO:: Uncomment to activate timeout signal: ,
        ...(method === 'POST' && props.body ? { body: JSON.stringify(props.body) } : {}),
        ...options,
      });

      const contentLength =
        (result.headers.has('Content-Length') && result.headers.get('Content-Length')) || '';
      const json = contentLength !== '0' ? await result.json() : null;

      if (!json) {
        return null;
      }

      return json;
    } catch (error) {
      console.error(`Error fetching data: ${(error as Error).message}`);

      throw error;
    }
  };
}
