'use client';
import React from 'react';

const userAnimationReducedPreference =
  typeof window !== 'undefined' && window.matchMedia('(prefers-reduced-motion: reduce)').matches;

const AnimationReducedPreferenceContext = React.createContext<boolean | undefined>(undefined);
const AnimationReducedPreferenceSetter = React.createContext<
  React.Dispatch<React.SetStateAction<boolean>> | undefined
>(undefined);

export function AnimationReducedPreferenceProvider({ children }: React.PropsWithChildren) {
  const [animationReduced, setAnimationReduced] = React.useState<boolean>(
    userAnimationReducedPreference || false
  );

  return (
    <AnimationReducedPreferenceContext value={animationReduced}>
      <AnimationReducedPreferenceSetter value={setAnimationReduced}>
        {children}
      </AnimationReducedPreferenceSetter>
    </AnimationReducedPreferenceContext>
  );
}

export default function useAnimationReduced() {
  const context = React.use(AnimationReducedPreferenceContext);

  if (context === undefined) {
    throw new Error('useAnimationReduced must be used within a AnimationReducedPreferenceProvider');
  }

  return context;
}

export function useSetAnimationReduced() {
  const context = React.use(AnimationReducedPreferenceSetter);

  if (context === undefined) {
    throw new Error(
      'useSetAnimationReduced must be used within a AnimationReducedPreferenceProvider'
    );
  }

  return context;
}

export function useAnimationReducedAtom() {
  return [useAnimationReduced(), useSetAnimationReduced()] as const;
}
