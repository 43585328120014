'use client';

import config from '@haaretz/l-config';
import buildBaseImgSrc from '@haaretz/s-image-utils/buildBaseImgSrc';
import buildBaseImgSrcset from '@haaretz/s-image-utils/buildBaseImgSrcset';
import buildSizesString from '@haaretz/s-image-utils/buildSizesString';
import getAspectData from '@haaretz/s-image-utils/getAspectData';
import getImgTitle from '@haaretz/s-image-utils/getImgTitle';
import s9 from 'style9';

import NextImage from './CustomNextImage';

import type { ImageProps as NextImageProps } from './CustomNextImage';
import type { ImageAspect } from '@haaretz/s-fragments/gql-types';
import type { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';
import type { SizesType } from '@haaretz/s-image-utils/buildSizesString';
import type { InlineStyles, StyleExtend } from '@haaretz/s-types';
const c = s9.create({
  base: {
    height: 'auto',
    maxWidth: '100%',
    verticalAlign: 'middle',
    width: '100%',
  },
});

export type HtzImageProps = Omit<
  NextImageProps,
  'className' | 'style' | 'src' | 'sizes' | 'width' | 'height' | 'alt'
> &
  Omit<ImageFragment, 'files' | '__typename'> & {
    /**
     * CSS declarations to be set as inline `style` on the
     * html element.
     *
     * By setting values of CSS Custom Properties based on
     * props or state in the consuming component (where
     * the value of `inlineStyle` is passed), `inlineStyle`
     * can be used as an API contract for setting dynamic
     * values to styles created with `style9.create()`:
     *
     * @example
     * ```ts
     * import s9 from 'style9';
     * const { styleExtend, } = s9.create({
     *   styleExtend: {
     *     color: 'var(--color-based-on-prop)',
     *   },
     * });
     *
     * function MyButton(props) {
     *   const inlineStyle = {
     *     '--color-based-on-prop': props.color,
     *   },
     *
     *   return (
     *    <Button
     *      styleExtend={[ styleExtend, ]}
     *      inlineStyle={inlineStyle}
     *    />
     *   );
     * }
     * ```
     */
    inlineStyle?: InlineStyles;
    /**
     * An array of `Style`s created by `style9.create()`.
     * WARNING: **_do not_** pass simple CSS-in-JS object.
     * The items in the array must be created with Style9's
     * `create` function.
     * The array can also hold falsy values to assist with
     * conditional inclusion of `Style`s:
     *
     * @example
     * ```ts
     * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
     * <Button styleExtend={[ someCondition && foo, bar, ]} />
     * ```
     */
    styleExtend?: StyleExtend;
    /**
     * Data for generating image urls and attributes.
     */
    imgData: ImageFragment['files'][number];
    /**
     * An object in the shape provided by the GraphQL API.
     */
    aspect: ImageAspect;
    /**
     * The sizes attribute, contains an array of objects to create the sizes string,
     * the "from" determines the media condition and the "size" determines the source size value.
     *
     * @example
     * ```ts
     * [
     *  { from: 's', size: '284px' },
     *  { from: 'm', size: '348px' },
     *  { from: 'l', size: '314px' },
     *  { from: 'xl', size: '336px' },
     *  { from: 'xxl', size: '380px' },
     *  { size: 'calc(50vw - 36px)', },
     * ]
     * ```
     */
    sizes: SizesType;
    /**
     * Array of widths to create the srcSet, used to determine
     * the img width and the width descriptor. Automatically sorts
     * the links and the descriptor from smallest to largest.
     */
    widths: Array<number>;
    /**
     * Array of heights to create the srcSet, used to determine the img height.
     * Must be same length as width array.
     */
    heights?: Array<number>;
    envOverride?: TEnv;
  };

export default function HtzImage({
  alt,
  aspect,
  caption,
  contentId,
  credit,
  envOverride,
  heights,
  imgData,
  inlineStyle,
  loading,
  onError,
  onLoad,
  photographer,
  priority,
  sizes,
  url: _url,
  widths,
  styleExtend = [],
  ...attrs
}: HtzImageProps) {
  if (widths.length === 0) {
    throw new Error('[HtzImage]: the "widths" prop must be an array with at least one item');
  }
  if (heights && widths.length !== heights.length) {
    throw new Error(
      '[HtzImage]: the "widths" and "heights" props must have the same number of items'
    );
  }
  if (!Array.isArray(sizes)) {
    throw new Error('[HtzImage]: the "sizes" prop must be an array with at least one item');
  }

  const env = envOverride || config.get('env');
  const minWidth = Math.min(...widths);
  const minWidthIndex = widths.findIndex(num => num === minWidth);
  const matchingHeightToMinWidth = heights && heights[minWidthIndex];

  const { isCropped, aspectRatio, aspectData } = getAspectData(aspect, imgData);

  const title = getImgTitle(caption, credit);

  const srcUrl = buildBaseImgSrc({
    aspect,
    aspectData,
    aspectRatio,
    contentId,
    env,
    height: matchingHeightToMinWidth,
    imgData,
    isCropped,
    width: minWidth,
  });
  const sizesString = sizes && buildSizesString(sizes);
  const srcSetUrl = buildBaseImgSrcset({ src: srcUrl, widths, aspectRatio, heights, env });

  const { height, width } = aspectData ?? { width: imgData.width, height: imgData.height };

  return (
    <NextImage
      {...attrs}
      imgData={imgData}
      styleExtend={[c.base, ...styleExtend]}
      inlineStyle={inlineStyle}
      title={title}
      width={width}
      height={height}
      alt={alt}
      src={srcUrl}
      sizes={sizesString}
      srcSet={srcSetUrl}
      priority={priority}
      loading={loading}
      onLoad={onLoad}
      onError={onError}
    />
  );
}
