'use client';

import React from 'react';
import s9 from 'style9';

type HideSiblingsContextType = React.Dispatch<React.SetStateAction<string | null>>;

export interface HideSiblingsOnDemandProps extends React.PropsWithChildren {}

const c = s9.create({
  hide: {
    display: 'none',
  },
});

export const HideSiblingsContext = React.createContext<HideSiblingsContextType | null>(null);

export default function HideSiblingsOnDemand({ children }: HideSiblingsOnDemandProps) {
  const [onlyVisibleChildId, setOnlyVisibleChild] = React.useState<string | null>(null);

  let newChildren: React.ReactNode = null;

  if (children && Array.isArray(children) && onlyVisibleChildId !== null) {
    newChildren = children.map(child => {
      const element = child as React.ReactElement<HTMLElement>;

      return element?.props?.id !== onlyVisibleChildId ? (
        <div className={s9(c.hide)} key={child}>
          {child}
        </div>
      ) : (
        child
      );
    });
  } else {
    newChildren = children;
  }

  return <HideSiblingsContext value={setOnlyVisibleChild}>{newChildren}</HideSiblingsContext>;
}
