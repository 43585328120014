'use client';

import useUser from '@haaretz/s-atoms/user';
import { useGetMarketingDataPersonalQuery } from '@haaretz/s-queries/Rainbow';
import { createContext, use, useEffect, useMemo, useRef, useState } from 'react';

import useFacts from '../facts/FactsProvider';
import { useRainbowAvailableStatus } from '../RainbowProvider';
import useSlotsRegistration from '../slots/SlotsProvider';

import type { RainbowToolFragment } from '@haaretz/s-fragments/RainbowTool';
import type { GetMarketingDataPersonalQueryVariables } from '@haaretz/s-queries/Rainbow';

type TDataContext = RainbowToolFragment[];

interface DataProviderProps {
  /**
   * Default Rainbow-tool to display to user if response from server has timeout
   */
  defaultRainbowTools?: RainbowToolFragment[] | null;
  /**
   * seconds to wait for displaying Rainbow default-tools
   */
  defaultRainbowToolsTimeout?: number | null;
  /**
   * If true, anonymous users will see the default tool (if any)
   * even if the server returned a response after a default-tool
   * was displayed to the user
   */
  useDefaultOnlyForAnonymous: boolean;
  /**
   * When this prop value changes the data invalidates
   */
  invalidateProp: string | string[] | (() => string);
  children: React.ReactNode;
}

function getInavlidateKey(invalidateProp: DataProviderProps['invalidateProp']) {
  let invalidteKey = null;

  if (typeof invalidateProp === 'function') {
    invalidteKey = invalidateProp();
  } else if (Array.isArray(invalidateProp)) {
    invalidteKey = invalidateProp.join(',');
  } else {
    invalidteKey = invalidateProp;
  }

  return invalidteKey;
}

/**
 * @param slotId - Rainbowtool predicate creator for given slot
 * @returns A predicate to test if a given item targeted to a slot-id
 */
function isItemOfSlot(slotId: string) {
  return (item: RainbowToolFragment) => item.slotId === slotId;
}

const DataContext = createContext<TDataContext | null>(null);

export function DataProvider({
  invalidateProp,
  defaultRainbowTools,
  defaultRainbowToolsTimeout,
  useDefaultOnlyForAnonymous,
  children,
}: DataProviderProps) {
  const [tools, setTools] = useState<RainbowToolFragment[]>([]);
  const user = useUser('cookieValue');

  // Default tools variables:
  const queryTimeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const shouldUseDefaultOnlyForAnonymous =
    useDefaultOnlyForAnonymous && user.userType === 'anonymous';
  const isDefaultToolsInUse = useRef(false);
  const rainbowAvailability = useRainbowAvailableStatus();

  const slotsRegistration = useSlotsRegistration();
  const invalidateKey = getInavlidateKey(invalidateProp);

  const [facts, isPendingFacts] = useFacts();

  // Rainbow data request variables
  const queryVariables: GetMarketingDataPersonalQueryVariables = useMemo(
    () => ({
      facts: JSON.stringify(facts),
      slots: slotsRegistration.getSlotsList(),
    }),
    [facts, slotsRegistration]
  );

  // rainbow data fetcher
  const { refetch } = useGetMarketingDataPersonalQuery(queryVariables, { enabled: false });

  // method for clear request timeout.
  const clearQueryTimeout = () => {
    queryTimeoutRef.current && clearTimeout(queryTimeoutRef.current);
    queryTimeoutRef.current = undefined;
  };

  // Reset data on navigation
  useEffect(() => {
    setTools(currentTools => (currentTools.length ? [] : currentTools));
  }, [invalidateKey]);

  // Fetch Rainbow data
  useEffect(() => {
    if (!facts || Object.keys(facts).length === 0 || isPendingFacts) {
      return;
    }

    const fetcher = async () => {
      const shouldUseDefaultTools = !!defaultRainbowToolsTimeout && !!defaultRainbowTools?.length;
      isDefaultToolsInUse.current = false;

      // If the request takes too much time, use default rainbow-tool
      if (shouldUseDefaultTools) {
        queryTimeoutRef.current = setTimeout(() => {
          isDefaultToolsInUse.current = true;
          setTools(defaultRainbowTools);
          clearQueryTimeout();
        }, defaultRainbowToolsTimeout * 1000);
      }

      const { data, isError, isSuccess } = await refetch();

      if (isError) {
        setTools((shouldUseDefaultTools && defaultRainbowTools) || []);
        clearQueryTimeout();
      } else if (isSuccess) {
        // If the user is anonymous and the default-tools already set, then 'shouldSetTools' is false
        const shouldSetTools = !(shouldUseDefaultOnlyForAnonymous && isDefaultToolsInUse.current);

        if (shouldSetTools) {
          setTools(data?.Rainbow?.tools || []);
        }

        clearQueryTimeout();
      }
    };

    if (rainbowAvailability === 'enabled') {
      fetcher();
    }
  }, [
    defaultRainbowTools,
    defaultRainbowToolsTimeout,
    facts,
    isPendingFacts,
    refetch,
    shouldUseDefaultOnlyForAnonymous,
    rainbowAvailability,
  ]);

  return <DataContext value={tools}>{children}</DataContext>;
}

export default function useSlotData(slotId: string) {
  const rainbowAvailability = useRainbowAvailableStatus();
  const ctx = use(DataContext);

  if (!ctx && rainbowAvailability === 'missing') {
    throw new Error('DataContext is null. useSlotData probably was called outside a provider.');
  }

  const tool = ctx?.find(isItemOfSlot(slotId));

  return tool;
}
