'use client';
import * as React from 'react';

interface RainbowSlotProviderContextProps {
  onClose: () => void;
  opened: boolean;
  closing: boolean;
}

const RainbowSlotProviderContext = React.createContext<RainbowSlotProviderContextProps | undefined>(
  undefined
);

export default function RainbowSlotProvider({ children }: React.PropsWithChildren) {
  const [opened, setOpened] = React.useState(true);
  const [closing, setClosing] = React.useState(false);

  const onClose = React.useCallback(function onClose() {
    const timeout = setTimeout(() => {
      setOpened(false);
    }, 250);
    setClosing(true);

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, []);

  const value = React.useMemo(() => ({ onClose, opened, closing }), [onClose, opened, closing]);

  return <RainbowSlotProviderContext value={value}>{children}</RainbowSlotProviderContext>;
}

export function useRainbowSlotActions() {
  const actions = React.use(RainbowSlotProviderContext);

  if (actions === undefined) {
    throw new Error('useRainbowSlotActions must be used within a RainbowSlotProviderContext');
  }

  return actions;
}
