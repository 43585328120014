import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';
import fetchUtility from '@haaretz/s-fetch-utility';
import mergeURLSearchParams from '@haaretz/s-navigation-utils/mergeURLSearchParams';
import { useQuery } from '@tanstack/react-query';

import type { CircularObjectType } from '@haaretz/s-types';
import type { UseQueryOptions } from '@tanstack/react-query';

/**
 * To be used with `useClientQuery` to indicate which query is being used.
 * When adding a new query, add a new key here
 */

export type ClientQueryCacheKey =
  | 'abTestList'
  | 'accessListByIp'
  | 'recheckAccessListByIp'
  | 'updateReadingHistory'
  | 'paywallEmailValidation'
  | 'personalList'
  | 'rainbowPersonalizedCampaigns'
  | 'rainbowPurchaseVisit'
  | 'rainbowToolsStatistics'
  | 'writerAlerts'
  | 'newsletterRegistration'
  | 'gamesRichTextElement'
  | 'checkInterestChipRegistration'
  | 'getUserNickname'
  | 'checkArticleInReadingList'
  | 'commentsCount'
  | 'articleTagKey'
  | 'userRecommendations';

interface ClientQueryProps<
  TData,
  TVariables extends CircularObjectType<
    string,
    string | number | boolean | undefined | null
  > = never,
  TError = Error,
> {
  url: string;
  cacheKey: ClientQueryCacheKey;
  clientOptions?: UseQueryOptions<TData, TError, TData>;
  fetchOptions?: Omit<RequestInit, 'body'>;
  searchParams?: URLSearchParams;
  variables?: TVariables;
}

export default function useClientQuery<
  TData extends object,
  TVariables extends CircularObjectType<
    string,
    string | number | boolean | undefined | null
  > = never,
  TError = Error,
>({
  url,
  variables,
  clientOptions,
  fetchOptions,
  searchParams,
  cacheKey,
}: ClientQueryProps<TData, TVariables, TError>) {
  const isPostRequest = fetchOptions?.method === 'POST';

  const fetchSearchParam = mergeURLSearchParams(
    variables
      ? new URLSearchParams(discardNullableValues(variables as Record<string, string>))
      : undefined,
    searchParams,
    { mergeMethod: 'set' }
  );

  return useQuery<TData, TError>(
    [
      'all',
      cacheKey,
      url,
      fetchSearchParam?.toString(),
      ...(variables === undefined ? [] : [variables]),
    ].filter(Boolean),
    async ({ signal }) => {
      return await fetchUtility<TData, typeof variables>({
        url,
        searchParams: fetchSearchParam,
        ...(isPostRequest ? { method: 'POST', body: variables } : { method: 'GET' }),
        options: {
          cache: 'no-cache',
        },
      })({ signal });
    },
    {
      ...clientOptions,
      useErrorBoundary: false,
      enabled:
        typeof window !== 'undefined' &&
        (clientOptions?.enabled === undefined || clientOptions?.enabled),
    }
  );
}
