import useBi from '@haaretz/s-use-bi';
import * as React from 'react';

import type { BiDataOverrides } from '@haaretz/s-data-structure-types';

interface ObserverInfo {
  observer: IntersectionObserver;
  // The number of elements observed by the observer
  observed: number;
}

type IntersectionObserverConfig = Omit<IntersectionObserverInit, 'root'>;

// A memoized map of all initialized observers, one per config.
const observers = new Map<string, ObserverInfo>();

// We use this to store elements that were already reported as
// having entered the viewport in order to be able to avoid
// registering them in the observer again if the component rerenders
const intersectedElements = new WeakSet<Element>();

// We don't need this on the server
const impressionEvent =
  typeof CustomEvent !== 'undefined' &&
  new CustomEvent(
    'biImpression',
    // The custom even should not bubble up so that it doesn't
    // accidentally trigger observers on parent elements, if any exist.
    { bubbles: false }
  );

// TODO:
// This is just an example. Not sure what the default values should be
const defaultConfig: IntersectionObserverConfig = { threshold: 1 };

export interface UseImpressionObserverOpts {
  /** The data to send to BI when the element is observed */
  biData: BiDataOverrides;
  /**
   * Options for the Intersection Observer instance,
   * defining when the element is considered to be in the viewport.
   */
  config?: IntersectionObserverConfig;
  /** Disable observing the element */
  disabled?: boolean;
  /** A ref to the element being observed */
  elementRef: React.RefObject<Element | null> | null;
  /**
   * A callback that will be fired when the element intersects
   * with the viewport. It **should not** be used to send BI events,
   * as that is handled by the hook itself.
   * Useful when needing to triggering actions in addition to sending data to the BI server, at the same time.
   */
  onObserve?: (data: BiDataOverrides) => void;
}

export default function useImpressionObserver({
  elementRef,
  biData,
  config = defaultConfig,
  disabled = false,
  onObserve,
}: UseImpressionObserverOpts) {
  const sendBiData = useBi('impression');
  React.useEffect(() => {
    const element = elementRef && elementRef.current;
    if (disabled || !element) return undefined;

    const sendImpression = onObserve
      ? (data: BiDataOverrides) => {
          onObserve(data);
          sendBiData(data);
        }
      : sendBiData;

    const unobserve = observeImpression({
      element,
      biData,
      sendImpression,
      config,
    });

    return unobserve;
  }, [biData, config, disabled, elementRef, onObserve, sendBiData]);
}

function observerCallback(entries: IntersectionObserverEntry[], _observer: IntersectionObserver) {
  for (const entry of entries) {
    const { isIntersecting, target } = entry;

    if (isIntersecting && impressionEvent) target.dispatchEvent(impressionEvent);
  }
}

type ObserveImpressionOpts = Omit<UseImpressionObserverOpts, 'disabled' | 'elementRef'> & {
  element: Element;
  sendImpression: ReturnType<typeof useBi>;
};

function observeImpression({
  element,
  biData,
  sendImpression,
  config = defaultConfig,
}: ObserveImpressionOpts) {
  let wasCleanedUp = false;
  const serializedConfig = JSON.stringify(config);
  if (intersectedElements.has(element)) return () => undefined;

  const existingObserver = observers.get(serializedConfig);
  const observerInfo = existingObserver || {
    observer: new IntersectionObserver(observerCallback, config),
    observed: 0,
  };

  if (!existingObserver) observers.set(serializedConfig, observerInfo);

  const { observer } = observerInfo;
  observer.observe(element);
  observerInfo.observed += 1;

  element.addEventListener('biImpression', onObserve);

  return unobserve;

  function unobserve() {
    observer.unobserve(element);
    element.removeEventListener('biImpression', onObserve);

    // Only decrease the number of observed elements once
    if (!wasCleanedUp) observerInfo.observed -= 1;
    wasCleanedUp = true;

    if (observerInfo.observed === 0) {
      observer.disconnect();
      observers.delete(serializedConfig);
    }
  }

  function onObserve(evt: Event) {
    evt.stopPropagation();
    sendImpression(biData);
    intersectedElements.add(element);
    unobserve();
  }
}
