'use client';

import useArticleId from '@haaretz/s-atoms/articleId';
import usePlatform from '@haaretz/s-atoms/platform';
import useUser from '@haaretz/s-atoms/user';
import { usePathname } from 'next/navigation';
import { useEffect, useCallback } from 'react';

import { getSiteProducts } from '../utils';

import requestGamCampaignIds from './requestGamCampaignIds';

import type { HtzUser, Site } from '@haaretz/s-types';

interface GamStorageType {
  lastModifiedDateTime: number;
  gamCampaigns: string[];
  hash: string;
}

const targetingProviders = new Map();
const GAM_CAMPAIGNS_STORAGE_KEY = 'GAM_Targeting_Campaigns';

/**
 * Hashes a string (Non secure)
 * @param str - string to hash
 * @returns a hash for given string
 */
function hashJoaat(str: string) {
  let a = 0;
  for (let c = str.length; c >= 0; c = c - 1) {
    a += str.charCodeAt(c);
    a += a << 10;
    a ^= a >> 6;
    a += a << 3;
    a ^= a >> 11;
  }
  return (((a + (a << 15)) & 4294967295) >>> 0).toString(16);
}

export default function GptGlobalTargeting() {
  const globalTargetingFunc = useGlobalAdManagerTargetings();

  useEffect(() => {
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(() => {
      globalTargetingFunc(googletag.pubads());
    });
  }, [globalTargetingFunc]);

  return null;
}

export function useGlobalAdManagerTargetings() {
  const platform = usePlatform();
  const user = useUser('cookieValue');
  const isApp = platform === 'app';
  const articleId = useArticleId();
  const pathname = usePathname();

  const puAdsTagetingFunc = useCallback(
    (pubAdsServise: googletag.PubAdsService) => {
      if (!pubAdsServise) {
        return;
      }

      const pathSegments = getPathSegments(pathname);

      targetingProviders.set('react', () => ['true']);
      targetingProviders.set('react_platform', () => (isApp ? ['App'] : null));
      targetingProviders.set('UserType', () => [
        user.userType === 'paying' ? 'payer' : user.userType,
      ]);
      targetingProviders.set('htz_user_type', () => getUserTypeForSite('htz', user));
      targetingProviders.set('tm_user_type', () => getUserTypeForSite('tm', user));
      targetingProviders.set('hdc_user_type', () => getUserTypeForSite('hdc', user));
      targetingProviders.set('section', () =>
        pathSegments && pathSegments.length > 0 ? [pathSegments[0]] : null
      );
      targetingProviders.set('sub_section', () =>
        pathSegments && pathSegments.length > 1 ? [pathSegments[1]] : null
      );
      targetingProviders.set('articleId', () => (articleId ? [articleId] : null));
      targetingProviders.set('bq_campaign_id', () => getGamCampaignIds(user));
      targetingProviders.set('referrer', () =>
        document.referrer.includes('facebook') ? ['facebook'] : null
      );
      targetingProviders.set('user', () => user.userTargeting);

      targetingProviders.forEach((valueGenerator, key) => {
        const value = valueGenerator && valueGenerator();

        if (value) {
          if (value instanceof Promise) {
            value.then(resolved => {
              pubAdsServise.setTargeting(key, resolved);
            });
          } else {
            pubAdsServise.setTargeting(key, value);
          }
        }
      });
    },
    [articleId, isApp, user, pathname]
  );

  return puAdsTagetingFunc;
}

// For Web :: Calculate the user type for a site by its product
function getUserTypeForSite(site: Site, user: HtzUser) {
  if (user.userType === 'anonymous') {
    return 'anonymous';
  }

  let userType;
  const { products = [] } = user;

  const siteProducts = getSiteProducts(site);
  const userSubscribedProduct = products.find(
    prod => siteProducts.includes(prod.prodNum) && prod.status === 'SUBSCRIBED'
  );

  if (userSubscribedProduct) {
    userType = userSubscribedProduct.isTrial ? 'trial' : 'payer';
  } else {
    userType = 'registered';
  }

  return userType;
}

// Breaks the window URL to path-segments (sections)
function getPathSegments(pathname: string) {
  return pathname && pathname.split('/').filter(part => /^[^.]+$/.test(part));
}

export function getGamCampaignIds(user: HtzUser): string[] | Promise<string[]> {
  const gamCampaignStorageValue = localStorage.getItem(GAM_CAMPAIGNS_STORAGE_KEY);
  const hashedEmail = hashJoaat(user?.userMail || '');
  let gamCampaigns = null;

  if (gamCampaignStorageValue && validateGamData(gamCampaignStorageValue, hashedEmail)) {
    const gamCampaignJson: GamStorageType = JSON.parse(gamCampaignStorageValue);
    gamCampaigns = gamCampaignJson.gamCampaigns;
  } else {
    gamCampaigns = requestGamCampaignIds()
      .then(data => {
        return Promise.resolve({
          ...data,
          gamCampaigns: data.gamCampaigns?.map((id: number) => id.toString()), // GAM accepts only strings
        });
      })
      .then(data => {
        localStorage.removeItem(GAM_CAMPAIGNS_STORAGE_KEY);
        localStorage.setItem(
          GAM_CAMPAIGNS_STORAGE_KEY,
          JSON.stringify({
            lastModifiedDateTime: Date.now(),
            gamCampaigns: data.gamCampaigns,
            hash: hashedEmail,
          })
        );

        return Promise.resolve(data.gamCampaigns as string[]);
      });
  }

  return gamCampaigns;
}

/**
 * Tests if the Gstat value is valid or need to be updated.
 */
function validateGamData(gamData: string, hash: string) {
  let isValid = true;

  if (gamData) {
    try {
      const json = JSON.parse(gamData) as GamStorageType;
      const hashCode = json.hash;
      const lastModifiedDateTime = new Date(json.lastModifiedDateTime);
      const now = new Date();
      const today10am = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 0, 0);
      const yesterday10am = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 1,
        10,
        0,
        0
      );

      // schedule one request per a day begin on 10 o'clock
      isValid =
        hash === hashCode &&
        (now >= today10am
          ? lastModifiedDateTime >= today10am
          : lastModifiedDateTime >= yesterday10am && lastModifiedDateTime <= today10am);

      Date.now();
    } catch (e) {
      isValid = false;
    }
  } else {
    isValid = false;
  }

  return isValid;
}
