'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import zIndex from '@haaretz/l-z-index.macro';
import usePageType from '@haaretz/s-atoms/pageType';
import Card from '@haaretz/s-card';
import isStorybook from '@haaretz/s-common-utils/isStorybook';
import HtzLink from '@haaretz/s-htz-link';
import Icon from '@haaretz/s-icon';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';
import s9 from 'style9';

import type { BreakingNewsNotificationFragment } from '@haaretz/s-fragments/BreakingNewsNotification';

// `c` is short for `classNames`
const c = s9.create({
  typing: {
    ...typesetter(0),
    ...merge(
      mq({
        from: 'l',
        until: 'xxl',
        value: { ...typesetter(2) },
      }),
      mq({
        from: 'xxl',
        value: { ...typesetter(1) },
      })
    ),
  },
  spacing: {
    paddingTop: space(3),
    paddingBottom: space(3),
    paddingInlineStart: space(3),
    paddingInlineEnd: space(3),

    ...merge(
      mq({
        from: 'l',
        until: 'xl',
        value: {
          paddingInlineStart: `calc(${space(3)} + ((1lh - 1em) / 2))`,
          paddingInlineEnd: `calc(${space(3)} + ((1lh - 1em) / 2))`,
        },
      }),
      mq({
        from: 'xl',
        value: {
          paddingTop: space(4),
          paddingBottom: space(4),
          paddingInlineStart: `calc(${space(4)} + ((1lh - 1em) / 2))`,
          paddingInlineEnd: `calc(${space(4)} + ((1lh - 1em) / 2))`,
        },
      })
    ),
  },
  base: {
    '--click-area-size-override': 0,
    display: 'grid',
    gap: space(3),
    alignItems: 'start',
    gridTemplateColumns: '1fr auto',
    position: 'fixed',
    zIndex: zIndex('above'),
    backgroundColor: color('exclusive'),
    borderColor: color('secondary1100'),
    borderWidth: '1px',
    borderStyle: 'solid',
    color: color('neutral100'),
    width: `calc(100vw - (2 * ${space(3)}))`,
    left: '50%',
    transform: 'translateX(-50%)',
    transition: 'opacity 1s linear',

    ...merge(
      mq({
        from: 'xl',
        value: {
          maxWidth: space(270),
        },
      })
    ),
  },
  bottom: {
    bottom: space(4),
    ...merge(
      mq({
        from: 's',
        value: {
          bottom: space(8),
        },
      })
    ),
  },
  bottomHp: {
    bottom: space(20),
    ...merge(
      mq({
        from: 's',
        value: {
          bottom: space(8),
        },
      })
    ),
  },
  kicker: {
    fontWeight: 700,
  },
  close: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginInlineStart: 'auto',

    ...merge(
      mq({
        from: 'l',
        value: {
          marginTop: 'calc((1lh - 1em) / 2)',
        },
      })
    ),
  },
  closingAnimation: {
    opacity: 0,
  },
});

export interface BreakingNewsNotificationProps
  extends Pick<
    BreakingNewsNotificationFragment,
    'kicker' | 'title' | 'url' | 'creationDateTime' | 'articleId'
  > {
  onClose?: () => void;
}

const AUTO_CLOSE_TIMEOUT = 7200000; // 2 Hours in millis

export default function BreakingNewsNotification({
  kicker,
  title,
  url,
  creationDateTime,
  articleId,
  onClose,
}: BreakingNewsNotificationProps) {
  const pageType = usePageType();
  const autocloseTimeoutRef = React.useRef<ReturnType<typeof setTimeout>>(undefined);

  const [isClosing, setIsClosing] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);

  const isSection = pageType && ['Homepage', 'Section'].includes(pageType);

  const biAction = useBi('action');
  const biImpression = useBi('impression');

  const biData = React.useMemo(
    () => ({
      feature_type: 'Content',
      feature: 'Breaking News Strip',
      next_article_viewname: 'Breaking News Strip',
      next_article_id: articleId || '',
      campaign_details: title || '',
    }),
    [articleId, title]
  );

  // Close button onclick handler
  const handleOnClose = () => {
    biAction({ ...biData, action_id: 0 });
    onClose && onClose();

    setIsClosing(true);

    if (autocloseTimeoutRef.current) {
      clearTimeout(autocloseTimeoutRef.current);
    }
  };

  const onClosingAnimationEnded = () => {
    if (isClosing) {
      setIsVisible(false);
    }
  };

  React.useEffect(() => biImpression(biData), [biData, biImpression]);

  // Make the notification re-appear when url and title changes
  React.useEffect(() => {
    setIsVisible(true);
    setIsClosing(false);
  }, [title, url, creationDateTime]);

  React.useEffect(() => {
    if (isVisible && !autocloseTimeoutRef.current) {
      autocloseTimeoutRef.current = setTimeout(
        () => isVisible && setIsClosing(true),
        AUTO_CLOSE_TIMEOUT
      );
    }

    return () => autocloseTimeoutRef.current && clearTimeout(autocloseTimeoutRef.current);
  }, [isVisible]);

  return url && isVisible ? (
    <Card
      styleExtend={[
        c.base,
        c.typing,
        c.spacing,
        !isStorybook() && (isSection ? c.bottomHp : c.bottom),
        isClosing && c.closingAnimation,
      ]}
      onTransitionEnd={onClosingAnimationEnded}
      test-id="breaking-news-notification-element"
    >
      <HtzLink href={url}>
        {kicker ? <span className={s9(c.kicker)}>{`${kicker} `}</span> : null}
        <span>{title}</span>
      </HtzLink>

      <button
        className={s9(c.close)}
        onClick={handleOnClose}
        title={fork({ default: 'סגירה', hdc: 'close' })}
        data-testid="bn-close"
      >
        <Icon icon="close" />
      </button>
    </Card>
  ) : null;
}
