export default function mergeURLSearchParams(
  initialParams: URLSearchParams | string | null | undefined,
  additionalParams: URLSearchParams | string | null | undefined,
  options: { mergeMethod: 'append' | 'set' }
): URLSearchParams {
  const merged = new URLSearchParams(initialParams ?? undefined);

  if (additionalParams) {
    const additional = new URLSearchParams(additionalParams);
    for (const [key, value] of additional.entries()) {
      merged[options.mergeMethod](key, value);
    }
  }

  return merged;
}
